import { useATMStore, useContractStore, useApiStore, useGodWalletStore } from '@/stores';

export async function launchStartupInitializations() {
	const apiStore = useApiStore();
	const contractStore = useContractStore();
	const atmStore = useATMStore();
	const godWalletStore = useGodWalletStore();

	apiStore.subscribeConnectAPI.resume();
	contractStore.subscribeInitContractMetaInfo.resume();
	atmStore.subscribeUpdateATMList.resume();
	godWalletStore.subscribeToUpdateGodWalletTokens.resume();
}
