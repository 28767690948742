export enum ATMCashStatusEnum {
	'Много' = 1,
	'Есть' = 2,
	'Мало' = 3,
	'Отсутствует' = 4,
}

export enum ATMStatusEnum {
	'Онлайн' = 1,
	'Офлайн' = 2,
}

export enum ATMModelNameEnum {
	'XDiebold_Nixdorf' = 1,
	'ATEC' = 2,
	'GRG' = 3,
	'NCR' = 4,
	'Oki' = 5,
}
