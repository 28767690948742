import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from '@/App.vue';
import router from '@/router/router';
import { initStartupFunctions } from './helpers';
import { createYmaps } from 'vue-yandex-maps';

const app = createApp(App);

(async () => {
	app.use(createPinia());

	await initStartupFunctions();

	app.use(router);
	app.use(
		createYmaps({
			apikey: 'c47a1bc7-cde7-494c-af1d-7b5cf593cbf8',
		}),
	);

	app.mount('#app');
})();
