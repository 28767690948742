<script setup lang="ts">
import { type InputTypeHTMLAttribute } from 'vue';

defineProps<{
	placeholder?: string;
	type?: InputTypeHTMLAttribute;
}>();

const modelValue = defineModel<string | number>();
</script>

<template>
	<div :class="$style.input">
		<slot name="prependIcon" />
		<input
			v-model="modelValue"
			:placeholder="placeholder"
			:type="type"
			:class="$style.inputEl"
		/>
		<slot name="appendText" />
	</div>
</template>

<style lang="scss" module>
.input {
	width: 100%;
	color: colors.$darkest-gray-75;
	font-size: 14px;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.inputEl {
	width: 100%;
	color: colors.$darkest-gray;
	font-size: 14px;
	font-weight: 400;
	padding: 10px 12px;
	border: 1px solid colors.$border;
	border-radius: 8px;
	position: relative;
	line-height: 1;

	&::placeholder {
		font-size: 14px;
		color: colors.$gray-text;
		font-weight: 300;
		line-height: 1;
		opacity: 0.8;
	}
}
</style>
