import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAppStore = defineStore('app-store', () => {
	const isATMSidebarVisible = ref(false);
	const selectedATM = ref<ATMShort | undefined>();

	return {
		selectedATM,
		isATMSidebarVisible,
	};
});
