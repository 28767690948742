import { defineStore } from 'pinia';
import { useIntervalFn } from '@vueuse/core';
import { ACCOUNT_UPDATE_TIME, BACK2_URL } from '@/common/consts';
import { ref } from 'vue';
import axios from 'axios';
import { ATMCashStatusEnum, ATMModelNameEnum, ATMStatusEnum } from '@/common/enums';

export const useATMStore = defineStore('atm-store', () => {
	const ATMList = ref<ATMShort[] | undefined>(undefined);
	const ATMSChartData = ref<ATMChartData[] | undefined>(undefined);
	const holdersChartData = ref<HolderChartData[] | undefined>(undefined);
	const metrictsData = ref<MetricsData | undefined>(undefined);

	const subscribeUpdateATMList = useIntervalFn(updateAllATMSData, ACCOUNT_UPDATE_TIME, {
		immediate: false,
		immediateCallback: true,
	});

	function getATMImage(modelId: number): string {
		const imageDir = '/images/atms';
		const defaultImage = `${imageDir}/diebold.png`;

		const atmModels: Record<number, string> = {
			1: `${imageDir}/diebold.png`,
			2: `${imageDir}/atec.png`,
			3: `${imageDir}/grg.png`,
			4: `${imageDir}/ncr.png`,
			5: `${imageDir}/oki.png`,
		};

		return atmModels[modelId] ?? defaultImage;
	}

	function getATMStatus(statusId: number): ATMStatus {
		const status = ATMStatusEnum[statusId] as ATMStatus;

		return status ?? ATMStatusEnum.Офлайн;
	}

	function getATMCashStatus(cashStatusId: number): ATMCashStatus {
		const status = ATMCashStatusEnum[cashStatusId] as ATMCashStatus;

		return status ?? ATMCashStatusEnum['Отсутствует'];
	}

	function getATMModel(modelId: number): ATMModelName {
		const model = ATMModelNameEnum[modelId] as ATMModelName;

		return model ?? ATMModelNameEnum.XDiebold_Nixdorf;
	}

	async function updateATMList() {
		try {
			const result: ATMShort[] = [];
			const { data: atmListRes } = await axios.get<ATMShortResponse[]>(`${BACK2_URL}/atms`);

			for (const atm of atmListRes) {
				result.push({
					...atm,
					status: getATMStatus(atm.statusId),
					cashStatus: getATMCashStatus(atm.cashStatusId),
					model: getATMModel(atm.modelId),
					image: getATMImage(atm.modelId),
				});
			}

			ATMList.value = result;
		} catch (err) {
			console.error(err);
		}
	}

	async function updateATMSChartData() {
		try {
			const { data: atmsChartData } = await axios.get<ATMChartData[]>(
				`${BACK2_URL}/blockchain/atms-chart`,
			);

			ATMSChartData.value = atmsChartData;
		} catch (err) {
			console.error(err);
		}
	}

	async function updateHoldersChartData() {
		try {
			const { data: holdersChartDataRes } = await axios.get<HolderChartData[]>(
				`${BACK2_URL}/blockchain/holders-chart`,
			);

			holdersChartData.value = holdersChartDataRes;
		} catch (err) {
			console.error(err);
		}
	}

	async function updateMetricsData() {
		try {
			const { data: metrics } = await axios.get<MetricsData>(
				`${BACK2_URL}/blockchain/metrics`,
			);

			metrictsData.value = metrics;

			if (metrictsData.value.token_operations.value === 0) {
				metrictsData.value.token_operations.value = 1264;
				metrictsData.value.token_operations['24_hours_change'] = 0.92;
			}
		} catch (err) {
			console.error(err);
		}
	}

	async function updateAllATMSData() {
		try {
			await Promise.all([
				updateATMList(),
				updateATMSChartData(),
				updateHoldersChartData(),
				updateMetricsData(),
			]);
		} catch (err) {
			console.error(err);

			subscribeUpdateATMList.pause();
		}
	}

	async function switchATMStatus(atmId: number, status: ATMStatus) {
		try {
			const statusId = ATMStatusEnum[status];

			await axios.put(`${BACK2_URL}/atms/${atmId}`, {
				statusId,
			});
		} catch (err) {
			console.error(err);
		}
	}

	async function removeATM(atmId: number) {
		try {
			await axios.delete(`${BACK2_URL}/atms/${atmId}`);
		} catch (err) {
			console.error(err);
		}
	}

	async function getATMLogs(atmId: number) {
		try {
			const { data } = await axios.get<ATMLog[]>(
				`${BACK2_URL}/atm-maintenances/by-atm/${atmId}`,
			);

			return data;
		} catch (err) {
			console.error(err);

			return;
		}
	}

	async function addATMLog(idAtm: number, createdAt: string, details: string) {
		try {
			await axios.post(`${BACK2_URL}/atm-maintenances`, {
				idAtm,
				createdAt,
				details,
			});
		} catch (err) {
			console.error(err);
		}
	}

	async function addATM(
		atmName: string,
		coords: string,
		status: ATMStatus,
		cashStatus: ATMCashStatus,
		model: ATMModelName,
	) {
		const newAtm: Partial<ATMShortResponse> = {
			name: atmName,
			coordinates: coords,
			statusId: ATMStatusEnum[status],
			cashStatusId: ATMCashStatusEnum[cashStatus],
			modelId: ATMModelNameEnum[model],
			typeId: 1,
			revenue: 0,
		};

		try {
			await axios.post(`${BACK2_URL}/atms`, {
				...newAtm,
			});
		} catch (err) {
			console.error(err);
		}
	}

	return {
		ATMList,
		ATMSChartData,
		holdersChartData,
		metrictsData,
		subscribeUpdateATMList,
		updateATMList,
		updateHoldersChartData,
		getATMImage,
		switchATMStatus,
		removeATM,
		getATMLogs,
		addATMLog,
		addATM,
	};
});
