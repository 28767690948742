import BN from 'bn.js';

export const useTokenConverters = () => {
	// TODO: данный обьект необходимо заполнять и периодически обновлять запросом
	const tokenCurrency: TokenCurrency = {
		BYN: {
			USDT: 1,
			UNT: 1,
			BYN: 1,
		},
		USD: {
			USDT: 1,
			UNT: 1,
			BYN: 1,
		},
	};

	/**
	 * Конвертация нативного значения токена в значение с decimals
	 */
	function convertNativeToDecimals(nativeValue: BN, tokenDecimals: number) {
		const divisor = new BN(Math.pow(10, tokenDecimals));

		return nativeValue.div(divisor).toNumber();
	}

	/**
	 * Конвертация значения токена с decimals в нативное
	 */
	function convertDecimalsToNative(decimalsValue: number, tokenDecimals: number) {
		return new BN(Math.pow(10, tokenDecimals) * decimalsValue);
	}

	/**
	 * Конвертация значения токена с decimals в fiat
	 */
	function convertDecimalsToFiat(
		tokenName: string,
		decimalsValue: number,
		fiatName: CurrencyName = 'USD',
	) {
		const fiat = tokenCurrency[fiatName][tokenName];

		if (!fiat) return -1;

		return fiat * decimalsValue;
	}

	/**
	 * Конвертация значения токена в fiat в значение с decimals
	 */
	function convertFiatToDecimals(
		tokenName: string,
		fiatValue: number,
		fiatName: CurrencyName = 'USD',
	) {
		const fiat = tokenCurrency[fiatName][tokenName];

		if (!fiat) return -1;

		return fiatValue / fiat;
	}

	/**
	 * Конвертация нативного значения токена в fiat
	 */
	function convertNativeToFiat(
		tokenName: string,
		nativeValue: BN,
		tokenDecimals: number,
		fiatName: CurrencyName = 'USD',
	) {
		const decimals = convertNativeToDecimals(nativeValue, tokenDecimals);
		const fiat = convertDecimalsToFiat(tokenName, decimals, fiatName);

		if (fiat < 0) return -1;

		return fiat;
	}

	/**
	 * Конвертация значения токена в fiat в нативное
	 */
	function convertFiatToNative(
		tokenName: string,
		fiatValue: number,
		tokenDecimals: number,
		fiatName: CurrencyName = 'USD',
	) {
		const decimals = convertFiatToDecimals(tokenName, fiatValue, fiatName);
		const native = convertDecimalsToNative(decimals, tokenDecimals);

		if (native.lt(new BN(0))) return -1;

		return native;
	}

	return {
		convertNativeToDecimals,
		convertDecimalsToNative,
		convertDecimalsToFiat,
		convertFiatToDecimals,
		convertNativeToFiat,
		convertFiatToNative,
	};
};
