<script setup lang="ts">
import AppLayout from '@/containers/AppLayout.vue';
import { ref, onMounted } from 'vue';
import TheLogin from '@/components/TheLogin.vue';
import { vAutoAnimate } from '@formkit/auto-animate/vue';

const isAuthorized = ref(false);

function validateLogin() {
	isAuthorized.value = true;
	localStorage.setItem('isAuthorized', JSON.stringify(isAuthorized));
}

onMounted(() => {
	const isAuthorizedStorage = localStorage.getItem('isAuthorized');
	if (isAuthorizedStorage) {
		isAuthorized.value = JSON.parse(isAuthorizedStorage);
	}
});
</script>

<template>
	<div :class="$style.appContainer" v-auto-animate>
		<div id="targetModal" />
		<Suspense>
			<TheLogin v-if="!isAuthorized" @validate-login="validateLogin()" />
			<AppLayout v-else>
				<RouterView v-slot="{ Component, route }">
					<component :is="Component" :key="route.fullPath" />
				</RouterView>
			</AppLayout>
		</Suspense>
	</div>
</template>

<style src="@/assets/scss/global/index.scss" />
<style lang="scss" module>
.appContainer {
	.appContent {
		position: relative;
	}
}
</style>
