export const MAX_TEXTAREA_CHARACTERS = 300;

export const ACCOUNT_MNEMONIC_WORD_COUNT = 12;
export const ACCOUNT_UPDATE_TIME = 10000;

export const GODWALLET_MNEMONIC = '//Alice';
export const GODWALLET_ACCOUNT_NAME = 'GOD';

export const ACCOUNT_NATIVE_TOKEN_ADDRESS = 'native';

export const WSS_URL = 'wss://explorer.unitchain.io/';

export const BACK1_URL = 'http://146.185.209.81:8042';
export const BACK2_URL = 'https://back.unit.fyi';
