import { ApiPromise, WsProvider } from '@polkadot/api';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { ACCOUNT_UPDATE_TIME, WSS_URL } from '@/common/consts';

import '@/common/polkadot/';
import { useIntervalFn } from '@vueuse/core';

export const useApiStore = defineStore('api-store', () => {
	const api = ref<ApiPromise | undefined>(undefined);
	const isConnecting = ref(false);

	const subscribeConnectAPI = useIntervalFn(connectAPI, ACCOUNT_UPDATE_TIME, {
		immediate: false,
		immediateCallback: true,
	});

	async function connectAPI() {
		isConnecting.value = true;

		const apiUrl = WSS_URL;

		try {
			const wsProvider = new WsProvider(apiUrl);
			api.value = await ApiPromise.create({
				provider: wsProvider,
				throwOnConnect: true,
				noInitWarn: true,
			});

			await api.value.isReady;
		} catch (err) {
			console.error(err);
		} finally {
			isConnecting.value = false;
			subscribeConnectAPI.pause();
		}
	}

	return {
		api,
		isConnecting,
		subscribeConnectAPI,
		connectAPI,
	};
});
