<script setup lang="ts">
import VBtn from '@/components/VBtn.vue';
import VLabel from '@/components/VLabel.vue';
import VInput from '@/components/VInput.vue';
import { IdentificationIcon, LockClosedIcon } from '@heroicons/vue/20/solid';
import { computed, ref } from 'vue';

const emits = defineEmits<{
	validateLogin: [];
}>();

const login = ref<string | undefined>(undefined);
const password = ref<string | undefined>(undefined);

const isLoginBtnDisabled = computed(() => !login.value || !password.value);

function checkValidateLogin() {
	if (login.value === 'user' && password.value === 'demo123321') {
		emits('validateLogin');
	}
}
</script>

<template>
	<section :class="$style.loginBgr">
		<div :class="$style.login">
			<header :class="$style.modalHeader">Авторизация</header>

			<div :class="$style.loginItem">
				<VLabel title="Логин" :class="$style.label">
					<template #prependIcon>
						<IdentificationIcon :class="$style.icon" />
					</template>
				</VLabel>
				<VInput v-model="login" placeholder="Введите логин от учетной записи" />
			</div>

			<div :class="$style.loginItem">
				<VLabel title="Пароль" :class="$style.label">
					<template #prependIcon>
						<LockClosedIcon :class="$style.icon" />
					</template>
				</VLabel>
				<VInput
					v-model="password"
					placeholder="Введите пароль от учетной записи"
					type="password"
				/>
			</div>

			<VBtn
				title="Войти"
				theme="secondary"
				:disabled="isLoginBtnDisabled"
				:class="$style.btn"
				@click="checkValidateLogin"
			/>
		</div>
	</section>
</template>

<style lang="scss" module>
.login {
	width: 80%;
	max-width: 411px;
	height: 411px;
	max-height: 100vh;
	padding: 24px;
	border-radius: 10px;
	background-color: colors.$white;
	display: flex;
	flex-direction: column;
}

.loginBgr {
	width: 100vw;
	height: 100vh;
	background-color: colors.$blue;
	display: flex;
	align-items: center;
	justify-content: center;
}

.modalHeader {
	display: flex;
	align-items: center;
	font-size: 18px;
	line-height: 1;
	padding-bottom: 20px;
	border-bottom: 1px solid colors.$border;
	margin-bottom: 36px;
}

.icon {
	width: 18px;
}

.btn {
	margin-top: auto;
}

.label {
	margin-bottom: 12px;
}

.loginItem {
	margin-bottom: 18px;
}
</style>
