import { ACCOUNT_UPDATE_TIME, BACK1_URL } from '@/common/consts';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import axios from 'axios';
import { useIntervalFn } from '@vueuse/core';

export const useContractStore = defineStore('contract-store', () => {
	const contractsMetaInfo = ref<ContractMetaInfoResponse>([]);

	const contractATMMetaInfo = computed(
		() => contractsMetaInfo.value.filter((v) => v.name === 'ATM')[0],
	);

	const contractCapsuleMetaInfo = computed(
		() => contractsMetaInfo.value.filter((v) => v.name === 'Capsule')[0],
	);

	const subscribeInitContractMetaInfo = useIntervalFn(initContractMetaInfo, ACCOUNT_UPDATE_TIME, {
		immediate: false,
		immediateCallback: true,
	});

	async function initContractMetaInfo() {
		try {
			const { data } = await axios.get<ContractMetaInfoResponse>(`${BACK1_URL}/contracts`);

			contractsMetaInfo.value = data;

			subscribeInitContractMetaInfo.pause();
		} catch (err) {
			console.error(err);

			subscribeInitContractMetaInfo.resume();
		}
	}

	return {
		contractsMetaInfo,
		contractATMMetaInfo,
		contractCapsuleMetaInfo,
		subscribeInitContractMetaInfo,
		initContractMetaInfo,
	};
});
