import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useIntervalFn } from '@vueuse/core';
import {
	GODWALLET_ACCOUNT_NAME,
	GODWALLET_MNEMONIC,
	ACCOUNT_UPDATE_TIME,
	ACCOUNT_NATIVE_TOKEN_ADDRESS,
} from '@/common/consts';
import { useApiStore } from '@/stores';
import keyring from '@polkadot/ui-keyring';
import BN from 'bn.js';
import { useTokenConverters } from '@/hooks';

export const useGodWalletStore = defineStore('god-wallet-store', () => {
	const apiStore = useApiStore();
	const tokenConvertes = useTokenConverters();

	const accountGodWallet = ref<ExtendedKeyringPair | undefined>(getAccountGodWallet());

	const isInitializing = computed(() => !accountGodWallet.value?.tokenNative);

	const subscribeToUpdateGodWalletTokens = useIntervalFn(
		updateAccountNativeTokenData,
		ACCOUNT_UPDATE_TIME,
		{
			immediate: false,
			immediateCallback: true,
		},
	);

	function getAccountGodWallet() {
		const pair = keyring
			.getPairs()
			.filter((pair) => pair.meta.name === GODWALLET_ACCOUNT_NAME)[0];

		return pair ? pair : importAccount(GODWALLET_MNEMONIC, GODWALLET_ACCOUNT_NAME);
	}

	function importAccount(mnemonic: string, accountName: string) {
		const { pair } = keyring.addUri(mnemonic, undefined, { name: accountName });

		return pair;
	}

	async function updateAccountNativeTokenData() {
		try {
			if (!apiStore.api || !accountGodWallet.value) return;

			const info = apiStore.api.registry.getChainProperties();

			if (!info) return;

			const {
				data: { free },
			} = await apiStore.api.query.system.account(accountGodWallet.value.address);

			const name = 'UNT';
			const decimals = parseInt(info?.tokenDecimals.value.toLocaleString());
			const balance = new BN(free.toString());
			const balanceWithDecimals = tokenConvertes.convertNativeToDecimals(balance, decimals);
			const balanceFiat = tokenConvertes.convertNativeToFiat(name, balance, decimals);

			accountGodWallet.value.tokenNative = {
				address: ACCOUNT_NATIVE_TOKEN_ADDRESS,
				name,
				balance,
				balanceFiat,
				decimals,
				balanceWithDecimals,
			};
		} catch (err) {
			console.error(err);
		}
	}

	return {
		accountGodWallet,
		isInitializing,
		subscribeToUpdateGodWalletTokens,
		getAccountGodWallet,
	};
});
